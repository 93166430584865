import React, { useRef, useState } from 'react'
import * as styles from './product-hero.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";

import { Pagination } from "swiper";
import { GatsbyImage } from 'gatsby-plugin-image';
import { mapSiteName } from '../../../utils/mapping';
import { useLocale } from '../../../hooks';

const ProductHero = ({
    imageArray,
    title
}) => {
    const locale = useLocale()

    const swiperRef = useRef()

    const [currentImage, setCurrentImage] = useState(0)
    const slideChange = () => {
        if (swiperRef?.current?.swiper?.activeIndex)
            setCurrentImage(swiperRef?.current?.swiper?.activeIndex)
    }

    return (
        <div className={styles.heroContainer}>
            <aside className={styles.mediaThumbnail}>
                <Swiper
                    ref={swiperRef}
                    spaceBetween={20}
                    direction="vertical"
                    className={styles.swiperContainer}
                    onSlideChange={slideChange}
                    breakpoints={{
                        320: {
                            direction: "horizontal",
                            width: 352,
                            slidesPerView: 4
                        },
                        767: {
                            direction: "vertical",
                            height: 352,
                            width: 88,
                            slidesPerView: 4
                        }
                    }}
                // centeredSlides={true}
                >
                    {
                        imageArray.map((image, index) => (
                            <SwiperSlide
                                key={index}
                                onClick={() => setCurrentImage(index)}
                            >
                                <GatsbyImage
                                    objectFit='contain'
                                    className={styles.thumbnailImage}
                                    image={image.gatsbyImageData}
                                    alt={`${title}-${index} | ${mapSiteName[locale]}`}
                                    loading="eager"
                                />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </aside>
            <div>
                <GatsbyImage
                    image={imageArray[currentImage].gatsbyImageData}
                    alt={`${title || imageArray[currentImage].title} | ${mapSiteName[locale]}`}
                    className={styles.heroImage}
                    objectFit="contain"
                    loading="eager"
                />
            </div>
        </div>
    )
}

export default ProductHero