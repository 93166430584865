import React from 'react'
import * as styles from './product-info.module.scss'

import { Link } from 'gatsby';

import ButtonPrimary from '../../Shared/ButtonPrimary/ButtonPrimary';
import ProductDescription from '../ProductDescription/ProductDescription';
import { useLocalPath, useSiteDictionary } from '../../../hooks';

const ProductInfo = ({
    data,
    title
}) => {
    const {
        productDescription,
        yahooLink,
        rakuenLink,
        fullCharateristics,
        ingredients,
        productSize,
        sizeReference,
        brandPage,
        ecSite
    } = data

    const {
        rakutenButton,
        brandPageButton,
        yahooButton
    } = useSiteDictionary()

    const addToCart = () => {
        // console.log("Clicked Add to Cart Button");
    }

    return (
        // <div className={styles.infoContainer}>
        <div>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.desc}>{productDescription?.productDescription}</p>
            <div className={styles.brandPageButton}>
                {
                    brandPage
                        ? brandPage.slug
                            ? <ButtonPrimary
                                text={brandPageButton}
                                link={brandPage.slug}
                            />
                            : <ButtonPrimary
                                text={brandPageButton}
                                url={brandPage.externalLink}
                            />
                        : <></>

                }
            </div>
            {
                productSize && <div className={styles.productSizeContainer}>
                    {
                        sizeReference
                            ? <div className={styles.productSize}>
                                <input type="checkbox" className={styles.productCheckbox} id="product-size-selector" hidden />
                                <label htmlFor="product-size-selector">{productSize}</label>
                                <div className={styles.productPopup}>
                                    {
                                        sizeReference.map(({ productSize, slug }, index) => (
                                            <p className={styles.productSize} key={index}><Link to={useLocalPath(slug)} className={styles.productSize}>{productSize}</Link></p>
                                        ))
                                    }
                                </div>
                            </div>
                            : <div><p className={styles.productSize}>{productSize}</p></div>
                    }
                </div>
            }

            <div className={styles.buttonContainer}>
                {/* <div className={styles.button}>
                    <ButtonPrimary
                        text="Add to Cart"
                        clickHandler={addToCart}
                    />
                </div> */}
                {
                    yahooLink && <div className={styles.button}>
                        <ButtonPrimary
                            text={yahooButton}
                            url={yahooLink}
                        />
                    </div>
                }
                {
                    rakuenLink && <div className={styles.button}>
                        <ButtonPrimary
                            url={rakuenLink}
                            text={rakutenButton}
                        />
                    </div>
                }
                {
                    ecSite && <div className={styles.button}>
                        <ButtonPrimary
                            url={ecSite}
                            text="LOHACOからの購入はこちら"
                        />
                    </div>
                }
            </div>
            <div>
                <ProductDescription seeMoreData={fullCharateristics} ingredientsData={ingredients} />
            </div>
        </div>
    )
}

export default ProductInfo