import React from "react"

export const useSchema = (type, data) => {
    switch (type) {
        case "product":
            return {
                "@context": "http://schema.org",
                "@type": "Product",
                "name": data.title,
                "description": data.description,
                "image": data.images.map(({ file }) => {
                    return `https:${file.url}`
                }),
                "brand": {
                    "@type": "Brand",
                    "name": "Lipton"
                }
            }
    }
} 