import React, { useRef } from 'react'
import * as styles from './related-products.module.scss';

import { useSiteDictionary } from '../../../hooks';
import ProductCard from '../../Shared/ProductCard/ProductCard';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";

import ArrowLeft from '../../../static/arrow-left.svg'
import ArrowRight from '../../../static/arrow-right.svg'

const RelatedProducts = ({
    products
}) => {
    const {
        relatedProductsText
    } = useSiteDictionary()

    const swiperRef = useRef()

    const goNext = (ref) => {
        if (ref.current && ref.current.swiper) {
            ref.current.swiper.slideNext();
        }
        // ref?.current
    }

    const goPrev = (ref) => {
        if (ref.current && ref.current.swiper) {
            ref.current.swiper.slidePrev();
        }
    }

    return (
        <section className={`container container-lg ${styles.container}`}>
            <h3 className={styles.title}>{relatedProductsText}</h3>
            <div className={styles.grid}>
                {
                    products.map(({ slug, thumbnailImage, thumbnailTitle }, index) => (
                        <ProductCard
                            title={thumbnailTitle}
                            image={thumbnailImage}
                            slug={slug}
                            key={index}
                        />
                    ))
                }
            </div>
            <div className={styles.swiperContainer}>
                <ArrowLeft className={styles.arrowLeft} onClick={() => goPrev(swiperRef)} />
                <Swiper
                    spaceBetween={11}
                    slidesPerView={2}
                    ref={swiperRef}
                    className={styles.swiperClass}
                    loop={true}
                >
                    {
                        products.map(({ slug, thumbnailImage, thumbnailTitle }, index) => (
                            <SwiperSlide key={index}>
                                <ProductCard
                                    title={thumbnailTitle}
                                    image={thumbnailImage}
                                    slug={slug}
                                    key={index}
                                />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <ArrowRight className={styles.arrowRight} onClick={() => goNext(swiperRef)} />
            </div>
        </section >
    )
}

export default RelatedProducts