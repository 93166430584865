import React, { useEffect, useState } from 'react'

import { graphql } from 'gatsby'

import Seo from '../components/seo';
import ProductForm from "../components/Product/ProductForm/ProductForm";
import RelatedProducts from '../components/Product/RelatedProducts/RelatedProducts';
import { useSchema } from '../hooks/layout/useSchema';

const Product = ({
    pageContext,
    location,
    data: {
        contentfulPageProduct: {
            slug,
            seoTitle,
            seoImage,
            seoDescription,
            thumbnailTitle,
            canonicalSlug,
            schemaImages,
            ...restData
        },
        allContentfulPageProduct: {
            nodes: products
        }
    }
}) => {
    const [randomProducts, setRandomProducts] = useState([])
    useEffect(() => {
        // Randomize the array and take out 3
        const randomArray = products.splice(Math.floor(Math.random() * products.length), 3)
        setRandomProducts(randomArray)
    }, [])
    return (
        <div className='triangle triangle-left minheight'>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
                dynamicSeoImage={seoImage}
                schema={[
                    useSchema('product', {
                        title: thumbnailTitle,
                        description: seoDescription?.seoDescription,
                        images: (schemaImages && schemaImages[0]?.images) ? schemaImages[0].images : []
                    })
                ]}
            />
            <div className='content'>
                <ProductForm
                    title={thumbnailTitle}
                    data={restData}
                />
                <RelatedProducts products={randomProducts} />
            </div>
        </div>
    )
}

export const ProductQuery = graphql`
    query ProductQuery( $id: String!, $contentful_id: String! ){
        contentfulPageProduct(id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            thumbnailTitle
            productDescription {
                productDescription
            }
            seoImage: thumbnailImage {
                file {
                    url
                }
            }
            yahooLink
            rakuenLink
            productSize
            sizeReference {
              productSize
              slug
            }
            imageArray {
                images {
                    title
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            schemaImages: imageArray {
                images {
                    file {
                        url
                    }
                }
            }
            canonicalSlug {
                slug
            }
            brandPage {
                ... on ContentfulPageTemplate {
                    slug
                }
                ... on ContentfulComponentLink {
                    externalLink
                }
            }
            fullCharateristics {
                raw
                #references {
                #    ...richTextReferences
                #}
            }
            ingredients {
                raw
                #references {
                #    ...richTextReferences
                #}
            }
        }
        allContentfulPageProduct(filter: {id: {ne: ""}, contentful_id: {ne: $contentful_id}, slug: {ne: null}, thumbnailTitle: {ne: null}, thumbnailImage: {file: {url: {ne: null}}}}) {
            nodes {
                slug
                thumbnailTitle
                thumbnailImage {
                    gatsbyImageData
                }    
            }
        }
    }
`

export default Product