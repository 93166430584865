import React from 'react'

import * as styles from './product-card.module.scss'

import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useLocale, useLocalPath, useSiteDictionary } from '../../../hooks'

import ButtonPrimary from '../ButtonPrimary/ButtonPrimary'
import { mapSiteName } from '../../../utils/mapping'

const ProductCard = ({
    image,
    title,
    slug
}) => {
    const {
        buyNowButtonText
    } = useSiteDictionary()

    const locale = useLocale()

    return (
        // <div className={styles.gridTile}>
        //     <div className={styles.imageDiv}>
        //         <Link to={useLocalPath(slug)}>
        //             <GatsbyImage
        //                 image={image?.gatsbyImageData}
        //                 alt={`${(title || image.title)} | ${mapSiteName[locale]}`}
        //                 className={styles.image}
        //                 objectFit="contain"
        //             />
        //         </Link>
        //     </div>
        //     <Link to={useLocalPath(slug)}>
        //         <h4 className={styles.cardTitle}>{title}</h4>
        //     </Link>
        //     <div className={styles.empty}>&nbsp;</div>
        //     <ButtonPrimary link={slug} text={buyNowButtonText} extraClass={styles.buyNowButton} />
        // </div>
        <Link to={useLocalPath(slug)} className={styles.gridTile}>
            <div className={styles.imageDiv}>
                <GatsbyImage
                    image={image?.gatsbyImageData}
                    alt={`${(title || image.title)} | ${mapSiteName[locale]}`}
                    className={styles.image}
                    objectFit="contain"
                />
            </div>
            <h4 className={styles.cardTitle}>{title}</h4>
            <div className={styles.empty}>&nbsp;</div>
            <ButtonPrimary link={slug} text={buyNowButtonText} extraClass={styles.buyNowButton} />
        </Link>
    )
}

export default ProductCard