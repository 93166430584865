import React from 'react'
import * as styles from './product-description.module.scss'

import { useSiteDictionary } from '../../../hooks'
import ProductAccordion from '../ProductAccordion/ProductAccordion'

const ProductDescription = ({
    seeMoreData,
    ingredientsData
}) => {
    const {
        ingredientsText,
        seeMoreText
    } = useSiteDictionary()
    return (
        <div className={styles.descContainer}>
            {
                seeMoreData && <div>
                    <ProductAccordion data={seeMoreData} title={seeMoreText} label="see-more" />
                </div>
            }
            {
                ingredientsData && <div>
                    <ProductAccordion data={ingredientsData} title={ingredientsText} label="ingredients" />
                </div>
            }
        </div>
    )
}

export default ProductDescription