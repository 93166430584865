import React from 'react'
import * as styles from './product-form.module.scss';

import ProductHero from '../ProductHero/ProductHero';
import ProductInfo from '../ProductInfo/ProductInfo';

const ProductForm = ({
    title,
    data
}) => {
    const {
        imageArray,
        ...restData
    } = data
    return (
        <div className={`container container-xl ${styles.container}`}>
            <div className={styles.hero}>
                {/* <ProductHero imageArray={heroSlider[0].images} /> */}
                {
                    (imageArray && imageArray[0].images) &&
                    <ProductHero
                        imageArray={imageArray ? imageArray[0].images : []}
                        title={title}
                    />
                }
            </div>
            <div className={styles.info}>
                <ProductInfo data={restData} title={title} />
            </div>
        </div>
    )
}

export default ProductForm