import React from 'react'
import * as styles from './product-accordion.module.scss';

import { RichText } from '../../Shared/RichText/RichText';

import Close from '../../../static/close.svg'


const ProductAccordion = ({
    data,
    title,
    label = null
}) => {
    return (
        <div className={styles.accordionContainer}>
            <input
                type="checkbox"
                name=""
                id={(label || title).split(' ').join('-').toLowerCase()}
                className={styles.accordionCheckbox}
                hidden
            />
            <label htmlFor={(label || title).split(' ').join('-').toLowerCase()} className={styles.accordionLabel}>
                <span>{title}</span>
                <span className={styles.close}><Close /></span>
            </label>
            <div className={styles.accordionContent}>
                <RichText content={data} />
            </div>
        </div>
    )
}

export default ProductAccordion